import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "components/PageLayout";
import { Section } from "components/layout";
import SEO from "components/Seo";
import { RichText } from "components/RichText";

const Container = styled.div`
  text-align: center;
  padding: 3.2rem 4rem;
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "homepage" } }) {
        edges {
          node {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);

  const { html } = data.allFile.edges[0].node.childMarkdownRemark;

  return (
    <Layout>
      <SEO title="PlasmaJazz | professional supplier" />
      <Section>
        <Container>
          <RichText dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      </Section>
    </Layout>
  );
};

export default IndexPage;
