import styled from "styled-components";

export const RichText = styled.div`
  h1 {
    font-size: 1.8rem;
    line-height: 1.3;
  }
  h2 {
    font-size: 1.6rem;
    line-height: 1.3;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
`;
